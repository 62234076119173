import React, { useState, useEffect } from "react"
import "../assets/css/testimonials.scss"
import Quote from "../../static/quote.png"

export default ({ data = [] }) => {
  let [quote, setQuote] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      let next = quote + 1 < data.length ? quote + 1 : 0
      setQuote(next)
    }, 10000)
  })
  return (
    <section className="testimonials" id="testimonials">
      <h2>Client Reviews</h2>

      <section className="testimonial-container">
        <section className="testimonial">
          <div className="quote">
            <img alt="Quote" src={Quote} />
          </div>
          <div className="text">{data[quote].text}</div>
          <div className="attribute">{data[quote].author}</div>
        </section>
      </section>
    </section>
  )
}
