import React from "react"
import { graphql } from "gatsby"

import { useAnchor } from "../effects/anchor.effect"

import Layout from "../components/layout"
import Features from "../components/features"
import FullCTA from "../components/fullCTA"
import { BodyText } from "../components/bodyText"
import OverlayText from "../components/overlayText"
import Testimonials from "../components/testimonials"
import StylistsThumbnails from "../components/stylists"

import { Sort } from "../services/utils"

const Stylists = ({ data }) => {
  const article = data.tst.Page || {}
  if (!article.pageMetaData) {
    article.pageMetaData = {}
  }
  article.pageMetaData.url = article.url
  const ctas = Sort(article.ctas || [], article.ctasSort || "")
  const first = ctas[0]
  const theRest = ctas.slice(1, ctas.length)

  useAnchor()

  return (
    <Layout meta={article.pageMetaData || { title: article.name }}>
      <OverlayText className="no-hero">
        <BodyText article={article} />
        <StylistsThumbnails
          data={Sort(article.stylists || [], article.stylistsSort || "")}
        />
        <Features features={article.features} startSide="left" />
        <FullCTA data={first} />
        <Testimonials data={article.testimonials} />
        {theRest &&
          theRest.length > 0 &&
          theRest.map((cta, idx) => (
            <FullCTA data={cta} key={`s-cta-${idx}`} />
          ))}
      </OverlayText>
    </Layout>
  )
}

export default Stylists

export const query = graphql`
  query StylistsQuery($id: ID!) {
    tst {
      Page(where: { id: $id }) {
        name
        pageTitle
        subTitle
        text
        url
        ctas {
          ...CTA
        }
        ctasSort
        features {
          ...Feature
        }
        pageMetaData {
          ...Meta
        }
        stylists {
          ...Stylist
        }
        stylistsSort
        testimonials {
          ...Testimonial
        }
      }
    }
  }
`
