import React from "react"
import "../assets/css/stylists.scss"
import { FixedImage } from "../services/images"
import { Link } from "gatsby"

export default ({ data }) => {
  return (
    <section className="stylists">
      {data &&
        data.map(stylist => {
          return (
            <div className="stylist" key={stylist.image.image.id}>
              <Link to={`/${stylist.url}`}>
                <FixedImage
                  src={stylist.image.image.id}
                  alt={stylist.image.alt}
                />
                <div className="name">{stylist.name.split(" ")[0]}</div>
              </Link>
            </div>
          )
        })}
    </section>
  )
}
